import { createApp } from 'vue'
import App from './App.vue'
import VueTippy from 'vue-tippy'

import initMainMenu from '../MainMenu/main'
import initMobileMenu from '../MobileMenu/main'
import initQuickLinks from '../QuickLinks/main'
import initSocialLinks from '../SocialLinks/main'
import initFooter from '../Footer/main'
import initHeroSlider from '../HeroSlider/main'
import initTextTeaser from '../TextTeaser/main'
import initDownloadBox from '../DownloadBox/main'
import initModal from '../Modal/main'
import initQuickAddBox from '../QuickAddBox/main'
import initShopProductList from '../ShopProductList/main'
import initShopCart from '../ShopCart/main'
import initElasticSearch from '../ElasticSearch/main'
import initShopLogin from '../ShopLogin/main'
import initButton from '../Button/main'
import initShopCountrySwitcher from '../ShopCountrySwitcher/main'
import initCookieDisclaimer from '../CookieDisclaimer/main'
import initGeoIPOverlay from '../GeoIPOverlay/main'
import initCloudWordBox from '../CloudWordBox/main'

import initIndividualPrice from '../IndividualPrice/main'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

const init = () => {
  const app = createApp({
    render: (h) => h(App)
  })

  app.use(VueTippy)
  app.mount('#app')

  initMainMenu()
  initMobileMenu()
  initQuickLinks()
  initSocialLinks()
  initFooter()
  initHeroSlider()
  initTextTeaser()
  initDownloadBox()
  initModal()
  initQuickAddBox()
  initShopProductList()
  initShopCart()
  initElasticSearch()
  initShopLogin()
  initButton()
  initShopCountrySwitcher()
  initCookieDisclaimer()
  initGeoIPOverlay()
  initCloudWordBox()

  initIndividualPrice()
}

if (document.body.classList.contains('cookiebannerEnabled')) {
  window.addEventListener('CookiebotOnLoad', init)
} else {
  init()
}
